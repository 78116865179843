import { Config, Env, StringSet, URLSet } from '../config';

// 🚨 IMPORTANT! 🚨: Please avoid using 'PROD' env during development/testing as this affects analytics.
// Instead, use PP env, but change the baseUrl to use PROD endpoint to test with PROD data.
export const env: Env = (process.env.REACT_APP_ENV as Env) || 'PROD';

// 🚨 IMPORTANT! 🚨: Please avoid changing this, instad use config.local for the changes.
// baseURL: baseUrls.PROD to get the prod data
export const baseUrls: URLSet = {
    PROD: 'https://api-cdn.optussport.tv',
    PP: 'https://api-cdn.optussport.tv',
    STAGING: 'https://api-cdn.optussport.info',
    LOCAL: 'https://api-cdn.optussport.info',
};

export const baseMetadataUrls: URLSet = {
    PROD: 'https://static.sport.optus.com.au',
    PP: 'https://static.sport.optus.com.au',
    STAGING: 'https://static.sport-pp.optus.com.au',
    LOCAL: 'https://static.sport-pp.optus.com.au',
};

export const segmentLibUrls: URLSet = {
    PROD: 'https://cdn.segment.com/analytics.js/v1/GqHwwhJ7Rg2Zg8Pwcr6eiEuqnPORgHd0/analytics.min.js',
    PP: 'https://cdn.segment.com/analytics.js/v1/kD7mjFc2Qjny3TPHbBvazY3YjqW0fhGX/analytics.min.js',
    STAGING:
        'https://cdn.segment.com/analytics.js/v1/kD7mjFc2Qjny3TPHbBvazY3YjqW0fhGX/analytics.min.js',
    LOCAL: 'https://cdn.segment.com/analytics.js/v1/kD7mjFc2Qjny3TPHbBvazY3YjqW0fhGX/analytics.min.js',
};

export const dataDogApplicationIds: URLSet = {
    PROD: '5c47411a-3370-44be-bd57-aa05d83a14fa',
    PP: 'b40d416f-b9aa-4a2b-ba5d-bc5febfdf02b',
    STAGING: 'b40d416f-b9aa-4a2b-ba5d-bc5febfdf02b',
    LOCAL: 'b40d416f-b9aa-4a2b-ba5d-bc5febfdf02b',
};

export const dataDogClientIds: URLSet = {
    PROD: 'pub4c1010c141c3874f8f6d0964ce1469bf',
    PP: 'pubd6f5a5d20380a51d40e458d190ca444e',
    STAGING: 'pubd6f5a5d20380a51d40e458d190ca444e',
    LOCAL: 'pubd6f5a5d20380a51d40e458d190ca444e',
};

export const MUXENVKeys: StringSet = {
    PROD: 'mgd0ee5iankkv3gidl14rm7c0',
    PP: '7a9qp3smd3pg459s3s5l9grvc',
    STAGING: '7a9qp3smd3pg459s3s5l9grvc',
    LOCAL: '67j73ggfioi8fa3cse2ii6uv8',
};

export const drModeCheckURLs: URLSet = {
    PROD: 'https://dr.optussport.tv/assets-ctv.json',
    PP: 'https://dr.optussport.info/assets-ctv.json',
    STAGING: 'https://dr.optussport.info/assets-ctv.json',
    LOCAL: 'https://dr.optussport.info/assets-ctv.json',
};

export const brazeUrls: URLSet = {
    PROD: 'sdk.iad-07.braze.com',
    PP: 'sdk.iad-07.braze.com',
    STAGING: 'sdk.iad-07.braze.com',
    LOCAL: 'sdk.iad-07.braze.com',
};

export const brazeKeys: StringSet = {
    PROD: '25382c82-0f10-4999-a4bb-15d5678cb1ad',
    PP: '27f41786-fa12-41a5-a06a-b68bd9b41da9',
    STAGING: '27f41786-fa12-41a5-a06a-b68bd9b41da9',
    LOCAL: '27f41786-fa12-41a5-a06a-b68bd9b41da9',
};

export const onlineCheckURLs: StringSet = {
    // Alternatively: https://sport.optus.com.au/1x1.png
    PROD: 'https://static.sport.optus.com.au/1x1.png',
    PP: 'https://static.sport-pp.optus.com.au/1x1.png',
    STAGING: 'https://static.sport-pp.optus.com.au/1x1.png',
    LOCAL: 'https://static.sport-pp.optus.com.au/1x1.png',
};

const config_all: Config = {
    // ENV
    env,
    platform: 'ctv',

    isLocal: false,

    // API
    baseURL: baseUrls[env],
    baseMetadataURL: baseMetadataUrls[env],

    // App
    DPADThrottleDelta: 300, //300ms
    exitAppPopupEnabled: false,
    noAnimations: false,
    deviceManagementEnabled: true,

    // Debug Config
    debug: false, // Display On-Screen Console Logs
    BrazeDebug: false,
    MUXDebug: false,
    SegmentDebug: false,
    YoSpaceDebug: false,
    showPlayerStatus: false,
    showPlaybackVideoQualityStatus: false,

    // LG Magic Remote
    MRActive: false,
    MRThrottleDelta: 300, //300ms

    // Analytics
    isFireboltEnabled: false,
    isSegmentEnabled: true,
    segmentLibUrl: segmentLibUrls[env],
    isDatadogRumEnabled: false,
    dataDogApplicationId: dataDogApplicationIds[env],
    dataDogClientId: dataDogClientIds[env],
    dataDogReplayRate: 0, // Use '0' to turn off Replays on Datadog RUM
    dataDogSampleRate: 100,

    // Dashboard
    maxPanelsToRender: 20,
    maxAssetsToRender: 15,
    maxHeroAssetsToRender: 5,
    maxExploreAssetsToRender: 15,
    heroCarouselSlideInterval: 6 * 1000, // 6s
    preFetchImagesOfNAssets: 5,
    preFetchPanelsCount: 3,

    contentCardCMSActive: true,

    // Dashboard Window Size
    dashboardWindowMaxPanels: 6,
    dashboardWindowMaxCard: 10,

    // Token
    tokenExpiryThrottle: 5, // 5m

    // Network
    networkMaxRetry: 3, // 5Nos
    networkRetryDelayExponent: 2, // retryDelay : retryN ^ networkRetryDelayExponent
    networkTimeout: 6 * 1000, // 6s

    // Timers
    miniEPGProgressUpdate: 2000, // 2s

    // Player
    playerUITimeout: 10 * 1000, // 10s
    playbackSettingsActive: true,
    playbackSettingsSpeedActive: true,
    playbackSettingsAudioActive: true,
    playbackSettingsTextActive: false,

    adUIEnabledOnLive: false,
    adUIEnabledOnVod: false,
    adHolidayEnabled: true,
    adHolidayDuration: 180,
    trickplayOnStartOver: 'USE_ASSET_TAG',
    trickplayOnLive: 'USE_ASSET_TAG',
    enabledStartOverForLive: 'USE_ASSET_TAG',
    seekJumpTime: 10,
    scrubSeekPercentage: 2 / 100, // 2%

    // Playback
    playbackStreamType: 'dash',
    playbackDrmChoice: 'widevine',
    playbackSupportsCmafLive: false,
    playbackSupportsCmafVod: false,
    stallDetectorEnabled: false,

    // Player Quality
    playerQualityLevelSettingsBitRate: {
        high: 10000, // Not in use
        medium: 3700, // Max <=
        low: 1400, // Min <=
    },

    // Are you still watching? / AYSW
    AYSWActive: true,
    AYSWNotificationTimeout: 5 * 60 * 60 * 1000, // 5 Hrs

    // AYSWNotificationTimeout: 5 * 60 * 1000, // 5 Mins
    AYSWAutoExitTimeout: 60 * 1000, // 60s

    // Playback Session / PS
    PSActive: true,
    PSCutoff: 0.9, // 0-1, session will not be considered when the bookmark is greater than cut off playback duration.

    // MUX Analytics
    MUXActive: true,
    MUXENVKey: MUXENVKeys[env],

    // YoSpace
    YoSpaceActiveOnLive: false,
    YoSpaceActiveOnVod: false,
    YoSpaceVersion: 'v3',
    YoSpaceRetryCount: 1,

    // App Suspended
    ASActive: true,
    ASThreshold: 15 * 60 * 1000, // In milliseconds
    DataRefreshMilliseconds: 15 * 60 * 1000,
    PageExpiryPeriodMinutes: 5,

    // DR Mode
    drModeCheckURL: drModeCheckURLs[env],
    drModeCheckActive: true,

    onlineCheckURL: onlineCheckURLs[env],

    // EPG
    MEpgActive: true,

    // Braze
    BrazeActive: false, // All users
    BrazeOnlyForLoggedInUserActive: false, /// Initialize only for/when Logged in users, destroy when user logs out
    BrazeInAppMessagesActive: true,
    BrazeEventsActive: true,
    BrazeApiKey: brazeKeys[env],
    BrazeBaseUrl: brazeUrls[env],
    BrazeInAppMessagesHeadlessActive: true,
    BrazeContentCardActive: false,
};

export default config_all;
